import { render, staticRenderFns } from "./OsiguFooter.vue?vue&type=template&id=7d3cc15d&scoped=true"
import script from "./OsiguFooter.vue?vue&type=script&lang=js"
export * from "./OsiguFooter.vue?vue&type=script&lang=js"
import style0 from "./OsiguFooter.vue?vue&type=style&index=0&id=7d3cc15d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d3cc15d",
  null
  
)

export default component.exports